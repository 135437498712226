import 'leaflet/dist/leaflet.css';
import React, {useState, useEffect, useRef, useContext} from "react";
import {MapContainer} from 'react-leaflet';
import L,{latLngBounds} from 'leaflet';
import settings from '../../../utils/config.js';
import setCookie from '../../../utils/setCookie.js';
import Cookies from 'js-cookie';
import getDifferenceTime from '../../../utils/getDifferenceTime.js';
import getVehicleIcon from '../../../utils/getVehicleIcon.js';
import MapLayers from '../../MapLayers.js';
import Counters from './Counters.js';
import Clusters from './Clusters.js';
import Supervision from './SuperVision.js';
import SidebarToggleButton from '../../SidebarToggleButton.js';
import { DataContext } from '../../../providers/SidebarProvider.js';
import isMobileMode from '../../../utils/isMobileMode.js';

function handleClusterGroupMouseOver(e){
    const { layer } = e;
    const markers = layer.getAllChildMarkers();
    let popupContent = `
        <div class="popup-container">
            <table>
                <tbody>
                    <tr class="fs-14">
                        <td>
                            <img width="20px" src="${settings.vehicleIconUrl}/images/bk_logo.ico">
                            <b>Οχήματα</b>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="border-top-1">
                <div>
                    <table>
                        <tbody>
                            ${Object.values(markers).map(marker => `
                                <tr class= "fs-12">
                                    <td>${marker.options.sign}</td>
                                </tr>
                            `).join('')}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    `;
    const popup = L.popup({ maxHeight: 250, minWidth: 130 })
        .setContent(popupContent);

    layer.bindPopup(popup).openPopup();
}

export default function Map({fleetFilterVisible, setFleetFilterVisible, vehiclesData, selectedVehicles, setSelectedVehicles, vehicleStatus, setVehicleStatus, allMarkersVisible, setAllMarkersVisible}){
    const { showSidebar } = useContext(DataContext);
    const [map, setMap] = useState(null);
    const [activeLayer, setActiveLayer] = useState('Osm');
    const [icons, setIcons] = useState({});
    const [displayVehicle, setDisplayVehicle] = useState([]);
    const allMarkersVisibleRef = useRef(allMarkersVisible);
    const [statusCounters, setStatusCounters] = useState({
        notMovingVehicles: 0,
        movingVehicles: 0,
        overspeedVehicles: 0,
        reladiVehicles: 0,
        workVehicles: 0,
        outOfOrderVehicles: 0
    });

    function checkIfAllMarkersIsVisible(){
        if(displayVehicle.length > 0 && displayVehicle !== undefined){
            const bounds = map.getBounds();
            return displayVehicle.every(marker => bounds.contains([parseFloat(marker.Latitude), parseFloat(marker.Longitude)]));
        }
        else
            return true;
    }

    function fitMapToVehicleBounds(){
        let markerBounds = latLngBounds([]);
        if(displayVehicle.length > 0){
            displayVehicle.forEach(marker => {
                markerBounds.extend([marker.Latitude, marker.Longitude]);
            });
            map.flyToBounds(markerBounds,{duration: 0.3});
        }
    }

    function handleFocus() {
        setAllMarkersVisible((prevState) => {
            const newState = !prevState;
            if (newState)
                fitMapToVehicleBounds();

            return newState;
        });
    }

    function handleArrow(){
        if(fleetFilterVisible)
            setFleetFilterVisible(false);
        else
            setFleetFilterVisible(true);

        setTimeout(function(){ map.invalidateSize()}, 10);
    }
    
    function filterVehiclesByStatus(){
        if (vehicleStatus === 6) 
            return vehiclesData
                .filter(item => getDifferenceTime(item.sdate) >= 12)
                .map(item => item.TruckID);
        else
            return vehiclesData
                .filter(item => item.status === vehicleStatus && getDifferenceTime(item.sdate) < 12)
                .map(item => item.TruckID);
    }

    function selectedVehicleStatus(statusId){
        setAllMarkersVisible(true);
        setVehicleStatus(statusId);
        setSelectedVehicles(filterVehiclesByStatus());
    }

    useEffect(() => {
        if(vehicleStatus !== 0){
            if(vehicleStatus === 6)
                setDisplayVehicle(vehiclesData.filter(item => getDifferenceTime(item.sdate) >= 12));
            else
                setDisplayVehicle(vehiclesData.filter(item => item.status === vehicleStatus && getDifferenceTime(item.sdate) < 12));

            setSelectedVehicles(filterVehiclesByStatus());
        }
    }, [vehiclesData, vehicleStatus]);

    useEffect(() => {
        if(vehicleStatus === 0){
            if(selectedVehicles.length !== 0)
                setDisplayVehicle(vehiclesData.filter(item => selectedVehicles.includes(item.TruckID)));
            else
                setDisplayVehicle(vehiclesData);
        }
    }, [vehiclesData, selectedVehicles]);

    useEffect(() => {
        const mapLayer = Cookies.get('mapLayer');
        if (mapLayer)
            setActiveLayer(mapLayer);
    }, []);

    useEffect(() => {
        const loadIcons = async () => {
            const iconPromises = vehiclesData.map(async (vehicle) => {
                const icon = await getVehicleIcon(vehicle);
                return { id: vehicle.TruckID, icon };
            });
            const iconsArray = await Promise.all(iconPromises);
            const iconsMap = iconsArray.reduce((acc, { id, icon }) => {
                acc[id] = icon;
                return acc;
            }, {});
            setIcons(iconsMap);
        };

        if (vehiclesData)
            loadIcons();

    }, [vehiclesData]);

    useEffect(() => {
        allMarkersVisibleRef.current = allMarkersVisible;
    }, [allMarkersVisible]);

    useEffect(() => {
        if (!map) return;
        
        setTimeout(() => {
            if(isMobileMode() && !showSidebar)
                setAllMarkersVisible(true);
        }, 150);
    },[showSidebar]);

    useEffect(() => {
        if (!map) return;

        map.on('dragend', function () {
            if (allMarkersVisibleRef.current)
                setAllMarkersVisible(checkIfAllMarkersIsVisible());
        });
        
        map.on('zoomend', function () {
            if (allMarkersVisibleRef.current)
                setAllMarkersVisible(checkIfAllMarkersIsVisible());
        });

        map.on("baselayerchange", e => {
            setCookie("mapLayer", e.name, false);
        });

        if(allMarkersVisible)
            fitMapToVehicleBounds();

        if(vehiclesData.length > 0){
            const notMovingVehicles = vehiclesData.filter(vehicle => vehicle.status === 3 && getDifferenceTime(vehicle.sdate) < 12).length;
            const movingVehicles = vehiclesData.filter(vehicle => vehicle.status === 2 && getDifferenceTime(vehicle.sdate) < 12).length;
            const overspeedVehicles = vehiclesData.filter(vehicle => vehicle.status === 1 && getDifferenceTime(vehicle.sdate) < 12).length;
            const reladiVehicles = vehiclesData.filter(vehicle => vehicle.status === 5 && getDifferenceTime(vehicle.sdate) < 12).length;
            const workVehicles = vehiclesData.filter(vehicle => vehicle.status === 4 && getDifferenceTime(vehicle.sdate) < 12).length;
            const outOfOrderVehicles = vehiclesData.length - notMovingVehicles - movingVehicles - overspeedVehicles - reladiVehicles - workVehicles;

            setStatusCounters({
                notMovingVehicles,
                movingVehicles,
                overspeedVehicles,
                reladiVehicles,
                workVehicles,
                outOfOrderVehicles
            });
        }
        
        setTimeout(() => { map.invalidateSize() }, 10);
    }, [selectedVehicles, displayVehicle, setAllMarkersVisible]);

    return(
        <div>
            <MapContainer center={[37.983810, 23.727539]} zoom={6} maxZoom={18} minZoom={3} ref={setMap} attributionControl={false}>
                <MapLayers activeLayer={activeLayer}/>
                <SidebarToggleButton
                    fleetFilterVisible={fleetFilterVisible}
                    handleArrow={handleArrow}
                />
                <Supervision
                    allMarkersVisible={allMarkersVisible}
                    handleFocus={handleFocus}
                />
                <Counters
                    selectedVehicleStatus={selectedVehicleStatus}
                    notMovingVehicles={statusCounters.notMovingVehicles}
                    movingVehicles={statusCounters.movingVehicles}
                    overspeedVehicles={statusCounters.overspeedVehicles}
                    reladiVehicles={statusCounters.reladiVehicles}
                    workVehicles={statusCounters.workVehicles}
                    outOfOrderVehicles={statusCounters.outOfOrderVehicles}
                />
                <Clusters
                    displayVehicle={displayVehicle}
                    icons={icons}
                    handleClusterGroupMouseOver={handleClusterGroupMouseOver}
                />
            </MapContainer>
        </div>
    );
}