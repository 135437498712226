class VehicleInfo {
  constructor(truckID, sign, status, statusDuration, speed, direction, sDate) {
    this.id = truckID.toString();
    this.name = sign;
    this.status = status;
    this.statusDuration = statusDuration;
    this.speed = speed;
    this.direction = direction;
    this.sDate = sDate;
  }
}

function getVehiclesByCategoryAndCompany(vehiclesData, categoryId, companyId) {
  return vehiclesData
    .filter(vehicle => vehicle.CategoryID === categoryId && vehicle.CompanyID === companyId)
    .map(vehicle => new VehicleInfo (
      vehicle.TruckID.toString(),
      vehicle.Sign,
      vehicle.Status,
      vehicle.StatusDuration,
      vehicle.Speed,
      vehicle.Direction,
      vehicle.sDate
    ));
}

function groupByCompanyCategory(combined, categories, vehiclesData, companyId) {
  return combined
    .filter(item => item.CompanyID === companyId)
    .map(item => {
      const category = categories.find(cat => cat.CategoryID === item.CategoryID);
      const vehicles = getVehiclesByCategoryAndCompany(vehiclesData, category.CategoryID, companyId);

      if (vehicles.length > 0) {
        return {
          id: `company/${companyId}/${category.CategoryID}`,
          name: category.Category,
          vehicleIcon: category.CatName,
          children: vehicles
        };
      }
    })
    .filter(Boolean);
}
  
function groupByCategoryCompany(combined, companies, vehiclesData, categoryId) {
  return combined
    .filter(item => item.CategoryID === categoryId)
    .map(item => {
      const company = companies.find(comp => comp.CompanyID === item.CompanyID);
      const vehicles = getVehiclesByCategoryAndCompany(vehiclesData, categoryId, company.CompanyID);

      if(vehicles.length > 0) {
        return {
            id: `category/${categoryId}/${company.CompanyID}`,
            name: company.Company,
            children: vehicles
        };
      }
    })
    .filter(Boolean);
}

function groupByField(vehiclesData, fieldName, fieldValue) {
  return vehiclesData
    .filter(vehicle => vehicle[fieldName] === fieldValue)
    .map(vehicle => new VehicleInfo(
      vehicle.TruckID.toString(),
      vehicle.Sign,
      vehicle.Status,
      vehicle.StatusDuration,
      vehicle.Speed,
      vehicle.Direction,
      vehicle.sDate
    ));
}

export default function formatTreeData(data, filters){
  const result = [{
    id: "All",
    name: "Στόλος",
    children: []
  }];

  const pushNodeIfNotEmpty = (node) => {
    if (node.children.length > 0)
      result[0].children.push(node);
  };

  if(filters.team && !filters.category){
    data.companies.forEach(company => {
      let node = {
        id: `company/${company.CompanyID.toString()}`,
        name: company.Company,
        children: groupByField(data.vehicles, 'CompanyID', company.CompanyID)
      };

      pushNodeIfNotEmpty(node);
    });
  }
  else if(!filters.team && filters.category){
    data.categories.forEach(category => {
      let node = {
        id: `category/${category.CategoryID.toString()}`,
        name: category.Category,
        vehicleIcon: category.CatName,
        children: groupByField(data.vehicles, 'CategoryID', category.CategoryID)
      };

      pushNodeIfNotEmpty(node);
    });
  }
  else if(!filters.team && !filters.category){
    data.vehicles.forEach(vehicle => {
      result[0].children.push(new VehicleInfo(
        vehicle.TruckID.toString(),
        vehicle.Sign,
        vehicle.Status,
        vehicle.StatusDuration,
        vehicle.Speed,
        vehicle.Direction,
        vehicle.sDate
      ));
    });
  }
  else{
    if(filters.order[0] === 'team'){
      data.companies.forEach(company => {
        let node = {
          id: `company/${company.CompanyID.toString()}`,
          name: company.Company,
          children: groupByCompanyCategory(data.combined, data.categories, data.vehicles, company.CompanyID)
        };

        pushNodeIfNotEmpty(node);
      });
    }
    else{
      data.categories.forEach(category => {
        let node = {
          id: `category/${category.CategoryID.toString()}`,
          name: category.Category,
          vehicleIcon: category.CatName,
          children: groupByCategoryCompany(data.combined, data.companies, data.vehicles, category.CategoryID)
        };

        pushNodeIfNotEmpty(node);
      });
    }
  }

  return result;
}