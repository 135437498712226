const settingsVariables = {
    //server settings
    panopticumWebApiAuthUrl: "https://panopticumwebapi-v200.dyndns.org",
    //site settings
    subdomain: "609",
    refreshTimeIntervalMilliseconds: 10000,
    refreshJwtTimeIntervalMilliseconds: 60000,
    vehicleIconUrl: "https://panopticumwebassets.b-k.gr/px/2.1.0",
    requestLimit: 540,
    //vehicle status ids
    overSpeed: 1,
    moving: 2,
    notMoving: 3,
    work: 4,
    reladi: 5,
    outOfOrder: 6,
    //auth settings
    identityAuth: false,
    //map settings
    showGoogleMapsLayers: false,
    googleMapsApiKey: "AIzaSyC6oHsJ4Hg65EKA-ukZsuXHpNHM431Yszc",
    //vehicle label settings
    showOnlyPlateSignOnVehicleLabelSetting : true,
    showLatestTelemetrySignalTimestampOnVehicleLabelSetting: true,
    showEcoOnVehicleLabelSetting: true,
    showTemperatureOnVehicleLabel: true,
    showBrokenAlertTextOnVehicleLabel: true,
    showExtraLabelDataOnVehicleLabel: true
};

module.exports = settingsVariables;